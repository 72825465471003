
.react-datepicker__header {
    border-bottom: none;
    background-color: white;
    padding: 10px;
    margin-bottom: "50px";
  }
  
  .react-datepicker {
    box-Shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.08), 0px 2px 20px 0px rgba(0, 0, 0, 0.04);
    border: none;
  }
  
  .react-datepicker__today-button {
    padding: 0px;
    border-top: 1px solid #EEE;
    font-weight: normal;
  }
  .react-datepicker__current-month{
    margin: 10px;
  }
  
  .react-datepicker-time__input-container{
    width: "100% !important";
   
  }
  .react-datepicker__day--selected {
    background-color: #054254;
    color: #FFFFFF;
    border-radius: 50%;
    padding: 5px;
   
  }
  .react-datepicker__navigation--previous{
    margin-top: 10px;
    margin-left: 10px;
  }
  .react-datepicker__navigation--next{
    margin-top: 10px;
    margin-right: 10px;
  }
  
  .react-datepicker__day--selected:hover {
    border-radius: 50%;
  }
  .react-datepicker-time__input-container{
    width: 100%;
  }
  .react-datepicker-time__input{
    margin-left: 0;
    width: 100%;
  }
  .react-datepicker__input-time-container{
    margin: 0;
  }
  
  .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input{
    margin-left: 0;
  }
  .react-datepicker{
    right: 100px;
    width: 362px;
  }
  .react-datepicker__day{
    width: 36px;
    height: 36px;
    font-size: 12px;
    margin: 6px;
    color: #1F2937;
    align-items: center;
 
  }
  .react-datepicker__day:hover{
    border-radius: 50%;
   
  }
  .react-datepicker__day--selected {
    color: #FFFFFF; 
  }
  .react-datepicker__day-name{
    width: 36px;
    height: 36px;
    font-size: 14px;
    margin: 6px;
    color: #6B7280;
  }
  .react-datepicker__day--outside-month {
    color: #D1D5DB; 

  }
  .react-datepicker__day--keyboard-selected{
    border-radius: 50%;
    padding: 5px;
  }
  .react-datepicker-popper{
    z-index: 4;
  }