* {
    font-family: "Inter" !important;
}

#root {
    background-color: #FFFFFF;
    font-family: "Inter" !important;
}

.sideBar-main-modal {
    position: fixed;
    border-radius: 8px;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    transform-style: preserve-3d;
    z-index: 9999;
}

.sideBar-main-modal button {
    margin: 5px;
    padding: 10px;
    border: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;
}

.sideBar-main-modal button:hover {
    background-color: #0056b3;
}