.thumbDown {
    border-radius: 30% ;
    cursor: pointer!important;
    height: 40px!important;
    width: 40px!important;
    align-items: center;
    display: flex;
    justify-content: center;
    transition: background-color 0.3s ease, transform 0.1s ease;
}

.thumbDown:active {
    background-color: #991B1B !important;
    transform: scale(0.95);
}

.thumbUp {
    border-radius: 30% ;
    cursor: pointer!important;
    height: 40px!important;
    width: 40px!important;
    align-items: center;
    display: flex;
    justify-content: center;
    transition: background-color 0.3s ease, transform 0.1s ease;
}

.thumbUp:active {
    background-color: #5CB152 !important;
            transform: scale(0.95);
}

  