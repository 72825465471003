.closeWrapper  {
    position: fixed;
    border: 1px solid #054254;
    border-radius: 100%;
    height: 30px;
    width: 30px;
    z-index: 9999;
    left: 85px;
    top: 18px;
    background-color: white;
}

.wrapper {
  position: fixed;
  border: 1px solid #054254;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  z-index: 9999;
  left: 282px;
  top: 18px;
  box-shadow: inset;
  background-color: white;
}

.arrows {
  width: 20px;
  height: 20px;
  flex: 0 0 auto;
  position: relative;
  left: -2px;
  transform: rotate(50deg) scale(0.55);
  color: #054254; /* Replace with actual color variable or value */
  transition: transform 0.2s ease-in-out, color 0.2s ease-in-out;
  border: 0px solid; /* Assuming $borderSize is a variable */
  top: 4px;
}

.arrows .arrow,
.arrows .arrow_2 {
  width: 100%;
  height: 100%;
  position: absolute;
}

.arrows .arrow::before,
.arrows .arrow_2::before {
  content: '';
  width: 100%;
  height: 100%;
  border-width: 3px 3px 0 0; /* Adjust border-width to include bottom border */
  border-style: solid;
  border-color: currentColor;
  transition: transform 0.2s ease-in-out, border-color 0.2s ease-in-out;
  display: block;
  transform-origin: 100% 0;
  border-start-end-radius: 7px
}

.arrows .arrow {
  top: 0;
  left: 0;
  transition: top 0.2s ease-in-out, left 0.2s ease-in-out;
}

/* Move the ::after styles inside the :hover selector */
.arrows:hover .arrow::after {
  content: '';
  float: left;
  position: relative;
  top: -100%;
  width: 100%;
  height: 100%;
  border-width: 0 3px 0 0; /* Adjust border-width to include bottom border */
  border-style: solid;
  border-color: currentColor;
  transform-origin: 78% 0;
  transition: transform 0.2s ease-in-out, border-color 0.2s ease-in-out, height 0.2s ease-in-out;
}

.arrows .arrow_2 {
  top: -10px;
  left: 10px;
}

.arrows:hover {
  transform: rotate(45deg) scale(0.7);
  color: #054254; /* Replace with actual color variable or value */
}

.arrows:hover .arrow {
  top: -10px;
  left: 10px;
}

.arrows:hover .arrow::before,
.arrows:hover .arrow_2::before {
  border-color: currentColor;
  transform: scale(0.8);
}

.arrows:hover .arrow::after {
  transform: rotate(45deg);
  border-color: currentColor;
  height: 130%;
}

/* Close Arrow Css */

.close_arrows {
  width: 20px;
  height: 20px;
  flex: 0 0 auto;
  position: relative;
  left: 11px;
  transform: rotate(-135deg) scale(0.55);
  color: #054254; /* Replace with actual color variable or value */
  transition: transform 0.2s ease-in-out, color 0.2s ease-in-out;
  border: 0px solid; /* Assuming $borderSize is a variable */
  top: 4px;
}

.close_arrows .close_arrow,
.close_arrows .arrow_2_close {
  width: 100%;
  height: 100%;
  position: absolute;
}

.close_arrows .close_arrow::before,
.close_arrows .arrow_2_close::before {
  content: '';
  width: 100%;
  height: 100%;
  border-width: 3px 3px 0px 0; /* Adjust border-width to include bottom border */
  border-style: solid;
  border-color: currentColor;
  transition: transform 0.2s ease-in-out, border-color 0.2s ease-in-out;
  display: block;
  transform-origin: 100% 0;
  border-start-end-radius: 7px
}

.close_arrows .close_arrow {
  top: 0;
  left: 0;
  transition: top 0.2s ease-in-out, left 0.2s ease-in-out;
}

/* Move the ::after styles inside the :hover selector */
.close_arrows:hover .close_arrow::after {
  content: '';
  float: left;
  position: relative;
  top: -100%;
  width: 100%;
  height: 100%;
  border-width: 0 3px 0 0; /* Adjust border-width to include bottom border */
  border-style: solid;
  border-color: currentColor;
  transform-origin: 78% 0;
  transition: transform 0.2s ease-in-out, border-color 0.2s ease-in-out, height 0.2s ease-in-out;
}

.close_arrows .arrow_2_close {
  top: -10px;
  left: 10px;
}

.close_arrows:hover {
  transform: rotate(-135deg) scale(0.7);
  color: #054254; /* Replace with actual color variable or value */
}

.close_arrows:hover .close_arrow {
  top: -10px;
  left: 10px;
}

.close_arrows:hover .close_arrow::before,
.close_arrows:hover .arrow_2_close::before {
  border-color: currentColor;
  transform: scale(0.8);
}

.close_arrows:hover .close_arrow::after {
  transform: rotate(45deg);
  border-color: currentColor;
  height: 130%;
}
